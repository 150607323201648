// ================ Import Modules ================

// Import React and necessary hooks
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import AOS from "aos";
import "aos/dist/aos.css";

// Import component-specific styles
import "./index.scss";

// Define the component
const ContactTextArea = ({
  label,
  name,
  validationRules,
  succes,
  file,
  animation,
}) => {
  // ======================= State ======================= //
  // const fileInput = useRef(null);
  // const [fileName, setFileName] = useState(null);

  // ======================= Functions ======================= //

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  // Access form context
  const { register, isSubmitting, errors } = useFormContext();

  // Function to handle opening file explorer
  // const openFileExplorer = () => {
  //   if (fileInput.current === null) return;
  //   if (fileName) {
  //     handleDelete();
  //   } else {
  //     fileInput.current.click();
  //   }
  // };

  // // Function to handle file change
  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFileName(selectedFile ? selectedFile.name : null);

  //   // Clear the input value to handle the case of selecting the same file again
  //   event.target.value = null;
  // };

  // // Function to handle file deletion
  // const handleDelete = () => {
  //   setFileName(null);
  //   // Additional logic to handle file deletion if needed
  // };

  // useEffect(() => {
  //   if (succes) {
  //     handleDelete();
  //   }
  // }, [succes]);

  // Render the component
  return (
    <div className="contact-text-area" data-aos={animation}>
      <textarea
        {...register(name, validationRules[name])}
        name={name}
        disabled={isSubmitting}
        className={`contact-textarea ${errors[name] ? "is-invalid" : ""}`}
        rows={5}
        style={{ resize: "none" }}
        required
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label>{label}</label>
      {errors[name] && (
        <span className="error-message">{errors[name].message}</span>
      )}
      {/* <div
        className={`tooltip-container ${fileName ? "rm" : "add"}`}
        onClick={openFileExplorer}
      >
        <span className="tooltip">
          {fileName ? "Remove file" : "Attach file"}
        </span>
        {fileName ? (
          <div className="delete-icon" onClick={handleDelete}>
            &#x2715; <span>{fileName}</span>
          </div>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 21.47C11.9355 21.489 12.685 21.5 13.5 21.5C16.632 21.5 18.783 21.34 20.124 21.1865C21.225 21.0605 22.049 20.235 22.1705 19.134C22.3295 17.689 22.5005 15.2635 22.5005 11.5C22.5005 10.063 22.4755 8.82152 22.4355 7.75502C22.2605 7.26502 21.662 5.97102 19.8015 4.14702C17.83 2.21402 16.4655 1.67052 16.0415 1.53802C15.1946 1.51205 14.3473 1.49938 13.5 1.50002C10.3675 1.50002 8.21649 1.66002 6.87599 1.81352C5.77549 1.93952 4.95099 2.76502 4.82949 3.86652C4.72199 4.84302 4.60949 6.26752 4.54749 8.25002"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 1.75C16 2.9735 16.0415 4.197 16.091 5.217C16.1228 5.92071 16.4167 6.58708 16.9148 7.0852C17.4129 7.58331 18.0793 7.87715 18.783 7.909C19.803 7.9585 21.0265 8 22.25 8"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.648 18.6365V16.298C5.648 15.2045 4.7195 14.318 3.574 14.318C2.429 14.318 1.5 15.2045 1.5 16.298V18.682C1.5 20.79 3.291 22.5 5.5 22.5C7.709 22.5 9.5 20.7905 9.5 18.682V14.318C9.5 12.21 7.709 10.5 5.5 10.5C4.1405 10.5 2.94 11.147 2.217 12.1365"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}

        <input
          {...register(file)}
          ref={fileInput}
          name="file"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div> */}
    </div>
  );
};

// Export the component
export default ContactTextArea;
