// - Import dependencies
import React, { useRef, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import DOMPurify from "dompurify";
import ReCAPTCHA from "react-google-recaptcha";

import AOS from "aos";
import "aos/dist/aos.css";

// - Import components
import LoginInput from "../../../components/inputs/loginInput";
import PopUp from "../../../components/pop-up";

// - Import API
import { forgotPassword } from "../../../api/users";

// - Import styles
import "./index.scss";

// - Create EmailForm component
const EmailForm = () => {
  // - Declare a new state variable, which we'll call "email"
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const recaptcha = useRef();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // State to track if the screen height is less than 700px
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight < 700);

  // Function to update isSmallScreen based on window height
  const checkScreenHeight = () => {
    setIsSmallScreen(window.innerHeight < 700);
  };

  useEffect(() => {
    // Check screen height on component mount
    checkScreenHeight();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenHeight);
    };
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const captchaValue = recaptcha.current.getValue();
    const sanitizedEmail = DOMPurify.sanitize(data.email);

    if (!captchaValue) {
      setError("Please verify the reCAPTCHA!");
      setLoading(false);
      return;
    }

    // - Call the API to send the email
    try {
      const response = await forgotPassword({
        email: sanitizedEmail,
        captchaValue: captchaValue,
      });
      if (response.redirectToVerification) {
        navigate(`/auth/change-password/verify`, {
          state: { uniqueToken: response.uniqueToken },
        });
      }
      setSuccess(true);
      setError(null);
    } catch (error) {
      setError(error.response.data.message);
    }
    reset();
    setLoading(false);
  };

  // Clear error messages after 60 seconds
  useEffect(() => {
    // Set a timeout to clear error messages after 60 seconds (60000 milliseconds)
    const timeoutId = setTimeout(() => {
      setError(null);
      setSuccess(false);
    }, 60000);

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="email-form flex">
      <FormProvider {...{ register, handleSubmit, errors }}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
          <div className="form-container__logo" data-aos="fade-down">
            ?
          </div>
          <div className="title_container">
            <p className="title" data-aos="fade-right">
              Forgot your password?
            </p>
            <span className="subtitle" data-aos="fade-left">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </span>
          </div>
          <br />
          <LoginInput
            label="Email"
            name="email"
            type="email"
            placeholder="example@gmail.com"
            validationRules={{
              required: "Email is required",
              minLength: {
                value: 5,
                message: "Email must be at least 5 characters",
              },
              maxLength: {
                value: 128,
                message: "Email must be at most 128 characters",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Enter a valid email address",
              },
            }}
            isInvalid={errors.email}
            animation={"fade-right"}
          />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptcha}
            data-aos={isSmallScreen ? null : "fade-left"}
          />
          <button
            title="Send"
            type="submit"
            className="send_btn"
            data-aos={isSmallScreen ? null : "fade-right"}
          >
            <span>{loading ? "Loading..." : "Send"}</span>
          </button>
          <a
            href="/login"
            className="cancel"
            data-aos={isSmallScreen ? null : "fade-up"}
          >
            Cancel
          </a>
          {error && (
            <div className="email-form__error flex">
              <p>{error}</p>
            </div>
          )}
        </form>
      </FormProvider>
      <PopUp
        state="success"
        showPopup={success}
        onClose={() => setSuccess(false)}
      >
        <p>
          A secure password reset link has been sent to your email. Please check
          both your inbox and spam folder for prompt access.
        </p>
      </PopUp>
    </div>
  );
};

export default EmailForm;
