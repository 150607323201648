import React from "react";

import { useTheme } from "../../context/ThemeProvider";

import "./index.scss";

const Settings = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className={`settings ${theme}`}>
      <div className="settings__header">
        <h2>Settings</h2>
        <p>Customize your experience</p>
      </div>
      <div className="settings__content__theme">
        <div className="settings__content__theme__header">
          <h3>Appearance</h3>
          <p>Change how the ui feels and looks in your browser.</p>
        </div>
        <div className="divider"></div>
        <div className="settings__content__theme__body">
          <div className="settings__content__theme__body__title">
            <h5>Interface Theme</h5>
            <p>Select or customize your UI theme.</p>
          </div>

          <div className="settings__content__theme__body__themes">
            <div
              onClick={() => toggleTheme("light")}
              className={`settings__content__theme__body__themes__theme`}
            >
              <div
                className={`settings__content__theme__body__themes__theme__color ${
                  theme === "light" ? "active" : ""
                }`}
              >
                <div className="settings__content__theme__body__themes__theme__color__circles light">
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                </div>
              </div>
              <p>Light</p>
            </div>
            <div
              onClick={() => toggleTheme("dark")}
              className={`settings__content__theme__body__themes__theme`}
            >
              <div
                className={`settings__content__theme__body__themes__theme__color ${
                  theme === "dark" ? "active" : ""
                }`}
              >
                <div className="settings__content__theme__body__themes__theme__color__circles dark">
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                  <div className="settings__content__theme__body__themes__theme__color__circles__circle"></div>
                </div>
              </div>
              <p>Dark</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
