// ============================ Import All Modules ============================

// - React Dependencies
import React from "react";

// - Stylesheets
import "./index.scss";

// - Images

// - Components
import Header from "../../components/navigation/navbar";
import Home from "./home";
import ServicesSection from "./services";
import Footer from "../../components/footer";
import About from "./about";
import Team from "./team";
import WorkSection from "./work";
import Contact from "./contact";
import ScrollToTopButton from "../../components/buttons/scrollToTop";

const HomePage = () => {
  return (
    <div className="wrapper">
      <Header />
      <Home />
      <ServicesSection />
      <About />
      <Team />
      <WorkSection />
      <Contact />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default HomePage;
