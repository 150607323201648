// ===================================== Import Modules ======================================

// - import react modules
import React from "react";

// - import context
import { useTheme } from "../../../context/ThemeProvider";

// - import styles
import "./index.scss";

const Modal = ({ children, title, visible, onClose }) => {
  const { theme } = useTheme();

  return (
    <div
      className={`modal ${visible ? "modal--visible" : ""} `}
      onClick={onClose}
    >
      <div
        className={`modal__content ${theme === "dark" ? "dark" : "light"}`}
        onClick={(e) => e.stopPropagation()}
      >
        <svg className="close" onClick={onClose} viewBox="0 0 25 25">
          <path d="M18.1538 6.80768L6.15381 18.8077" />
          <path d="M6.15381 6.80768L18.1538 18.8077" />
        </svg>
        <div className="modal__content__body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
