// - Import styles
import "./index.scss";
// - Import dependencies
import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn";

import DOMPurify from "dompurify";

import AOS from "aos";
import "aos/dist/aos.css";

// - Import Context
import { TimerContext } from "../../../context/TimerProvider";

// - Import components
import LoginInput from "../../../components/inputs/loginInput";
import PopUp from "../../../components/pop-up";

// - Import api
import { resetPassword } from "../../../api/users";

const validationRules = {
  password: {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long",
    },
    maxLength: {
      value: 128,
      message: "Password must be at most 128 characters long",
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    },
  },
  repeatPassword: {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters long",
    },
    maxLength: {
      value: 128,
      message: "Password must be at most 128 characters long",
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    },
  },
};

// - Create ChangePassword component function
const PasswordForm = () => {
  // ==================== Context ==================== //
  const { useCountdownTimer } = useContext(TimerContext);

  // ==================== State ==================== //

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [passwordStrength, setPasswordStrength] = useState(0);

  // ==================== Variables ==================== //
  const countdownDuration = 240;
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  // ==================== Timer Hook ==================== //
  // Initialize the countdown
  const { rawCountdown, formattedTime } = useCountdownTimer(
    countdownDuration,
    () => {
      navigate("/");
    }
  );

  // ==================== Functions ==================== //
  const handlePasswordChange = (event) => {
    const password = event.target.value;
    const strength = zxcvbn(password).score;
    setPasswordStrength(strength);
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/");
      }, 6000);
    }
  }, [success, navigate]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const passwordValue = watch("password");
  const confirmNewPasswordValue = watch("repeatPassword");

  const onSubmit = async (data) => {
    setLoading(true);

    const sanitizedPassword = DOMPurify.sanitize(data.password);
    const sanitizedRepeatPassword = DOMPurify.sanitize(data.repeatPassword);

    const credentials = {
      password: sanitizedPassword,
      repeatPassword: sanitizedRepeatPassword,
      token: token,
    };
    // - Call the API to send the email
    try {
      await resetPassword(credentials);
      setSuccess(true);
      setError(null);
    } catch (error) {
      setError(error.response.data.message);
    }
    reset();
    setLoading(false);
  };

  // State to track if the screen height is less than 700px
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight < 700);

  // Function to update isSmallScreen based on window height
  const checkScreenHeight = () => {
    setIsSmallScreen(window.innerHeight < 700);
  };

  useEffect(() => {
    // Check screen height on component mount
    checkScreenHeight();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenHeight);
    };
  }, []);

  // Clear error messages after 60 seconds
  useEffect(() => {
    // Set a timeout to clear error messages after 60 seconds (60000 milliseconds)
    const timeoutId = setTimeout(() => {
      setError(null);
      setSuccess(false);
    }, 60000);

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="password-form flex">
      <FormProvider {...{ register, handleSubmit, errors }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="password-form__container flex"
        >
          <div className="password-form__title" data-aos="fade-down">
            <h2>Change Password</h2>
          </div>
          <p className="expire">
            This session will expire in{" "}
            <span className={`${rawCountdown < 10 ? "scale" : null}`}>
              {formattedTime}
            </span>{" "}
            seconds.
          </p>
          <div className="password-form__requirements flex">
            <p data-aos="fade-right">
              For enhanced account security, please consider the following
              password requirements:
            </p>
            <ul>
              <li data-aos="fade-left">
                Your password should be at least <b>8</b> characters long.
              </li>
              <li data-aos="fade-right">
                <b>Avoid</b> using easily guessable information in your
                password, such as your{" "}
                <b>username, email address, first name, or last name</b>.
              </li>
              <li data-aos="fade-left">
                Include a mix of uppercase and lowercase letters, numbers, and
                special characters for added complexity.
              </li>
              <li data-aos="fade-right">
                Do <b>not</b> reuse passwords across multiple accounts to
                prevent security vulnerabilities.
              </li>
              <li data-aos="fade-left">
                Change your password periodically to reduce the risk of
                unauthorized access.
              </li>
            </ul>
          </div>

          <LoginInput
            label="Password"
            name="password"
            type="password"
            placeholder="password"
            validationRules={validationRules}
            onChange={handlePasswordChange}
            animation={"fade-right"}
          />
          <LoginInput
            label="Repeat Password"
            name="repeatPassword"
            type="password"
            placeholder="password"
            validationRules={validationRules}
            animation={"fade-left"}
          />
          {passwordStrength !== null && (
            <div className="progress-bar-container" data-aos="fade-right">
              <div className="password-strength-indicator">
                <div className="strength-label">
                  {passwordStrength === 0 && "Very Weak"}
                  {passwordStrength === 1 && "Weak"}
                  {passwordStrength === 2 && "Moderate"}
                  {passwordStrength === 3 && "Strong"}
                  {passwordStrength === 4 && "Very Strong"}
                </div>
                <div
                  className={`progress-bar strength-${passwordStrength}`}
                  style={{ width: `${(passwordStrength + 1) * 20}%` }}
                ></div>
              </div>
            </div>
          )}
          <button
            title="Send"
            type="submit"
            className="password-form__send-btn"
            data-aos={isSmallScreen ? null : "fade-left"}
          >
            <span>{loading ? "Loading..." : "Send"}</span>
          </button>
          {passwordValue !== confirmNewPasswordValue && (
            <div className="password-form__error flex">
              <p>Passwords do not match</p>
            </div>
          )}
          {error && (
            <div className="password-form__error flex">
              <p>{error}</p>
            </div>
          )}
          <a
            href="/"
            className="cancel"
            data-aos={isSmallScreen ? null : "fade-up"}
          >
            Cancel
          </a>
        </form>
      </FormProvider>
      <PopUp
        children={
          <p>
            You successfully changed your password. You can now log in with your
            new password. This window will automatically close in a few seconds.
          </p>
        }
        state={"success"}
        showPopup={success}
        onClose={() => setSuccess(false)}
      />
    </div>
  );
};

// - Export ChangePassword component function
export default PasswordForm;
