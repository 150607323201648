// ============================ Import All Modules ============================

// - React Dependencies
import React, { useEffect } from "react";
import { Link as Scroll } from "react-scroll";

import AOS from "aos";
import "aos/dist/aos.css";

// - Stylesheets
import "./index.scss";

// - Images

// - Services
// ============================ Render ============================ //
const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="home" name="home">
      <div className="home__content">
        <div className="home__content__title">
          <h3 data-aos="fade-down">Make In Motion</h3>
          <h1 data-aos="fade-right">
            Empowering your <span className="highlight-brand">brand</span> in
            the digital landscape
          </h1>
        </div>
        <p data-aos="fade-left">
          At the forefront of the digital era, we specialize in{" "}
          <em>custom software solutions</em> that meet the{" "}
          <strong>unique needs</strong> of your business. Our expertise in
          crafting <span>resilient web applications</span> propels operational
          efficiency and business growth. Discover the synergy of innovation
          with our comprehensive web solutions – the{" "}
          <strong>foundation for your enterprise's future</strong>.
        </p>

        <div className="home__content__cta-container">
          <Scroll
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className="home__content__cta"
            data-aos="fade-up"
          >
            <span>Estimate Project</span>
            <svg
              width="14"
              height="21"
              viewBox="0 0 14 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 19L12 10.5L2 2"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Scroll>
          <Scroll
            to="services" // Assuming "services" is the section you want to scroll to
            spy={true}
            smooth={true}
            offset={-70} // You may need to adjust this value
            duration={500}
            className="home__content__cta-container__cta--secondary"
            data-aos="fade-up"
          >
            <span>Learn More</span>
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Home;
