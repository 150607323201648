// ===================| Filter Chip |=================== //

// =================== Imports ==================== //
import React from "react";

// - Stylesheets
import "./index.scss";

const FilterChip = ({ filter, selectedFilters, toggleFilter }) => {
  // =================== Render ==================== //
  return (
    <div
      className={`filter-chip ${
        selectedFilters.includes(filter) ? "selected" : ""
      }`}
      onClick={() => toggleFilter(filter)}
    >
      {filter}
    </div>
  );
};

export default FilterChip;
