import React, { useState } from "react";
import "./index.scss";

const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [selectOpen, setSelectOpen] = useState(false);
  const [tasks] = useState([
    {
      day: 10,
      month: date.getMonth(),
      year: date.getFullYear(),
      startTime: "10:00 AM",
      endTime: "11:30 AM",
      title: "Meeting",
      category: "work",
    },
    {
      day: 15,
      month: date.getMonth(),
      year: date.getFullYear(),
      startTime: "2:00 PM",
      endTime: "4:00 PM",
      title: "Gym",
      category: "health",
    },
    {
      day: 21,
      month: date.getMonth(),
      year: date.getFullYear(),
      startTime: "9:00 AM",
      endTime: "12:00 PM",
      title: "Doctor's appointment",
      category: "health",
    },
    // Add more tasks for this month as needed
  ]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthData = () => {
    const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
    const firstDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).getDay(); // Adjusted to get first day of the week
    const monthData = [];
    let day = 1;

    // Adjusted the start index to make Monday the first day of the week
    const startIndex = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < startIndex) || day > daysInMonth) {
          // Adjusted to start from the first day of the week
          week.push(null);
        } else {
          week.push(day);
          day++;
        }
      }
      monthData.push(week);
      if (day > daysInMonth) break;
    }

    return monthData;
  };

  const toggleSelect = () => {
    setSelectOpen(!selectOpen);
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value);
    const newYear = date.getFullYear();
    const daysInNewMonth = getDaysInMonth(newYear, newMonth);
    const currentDay = Math.min(date.getDate(), daysInNewMonth);
    setDate(new Date(newYear, newMonth, currentDay));
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value);
    const newMonth = date.getMonth();
    const daysInNewMonth = getDaysInMonth(newYear, newMonth);
    const currentDay = Math.min(date.getDate(), daysInNewMonth);
    setDate(new Date(newYear, newMonth, currentDay));
  };

  const handlePrevMonth = () => {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      date.getDate()
    );
    setDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    setDate(newDate);
  };

  const handleToday = () => {
    setDate(new Date());
  };

  const monthData = getMonthData();

  const generateMonthOptions = () => {
    return Array.from({ length: 12 }, (_, index) => (
      <option key={index} value={index}>
        {new Date(date.getFullYear(), index, 1).toLocaleString("default", {
          month: "long",
        })}
      </option>
    ));
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 10 }, (_, index) => (
      <option key={index} value={currentYear - 5 + index}>
        {currentYear - 5 + index}
      </option>
    ));
  };

  const categoryColors = {
    work: "#fa695f",
    health: "#b8e080",
    // Add more categories and colors as needed
  };

  return (
    <div className="calendar">
      <div className="navigation">
        <div className="navigation-left">
          <div className="select-container">
            {selectOpen ? (
              <>
                <select value={date.getMonth()} onChange={handleMonthChange}>
                  {generateMonthOptions()}
                </select>
                <select value={date.getFullYear()} onChange={handleYearChange}>
                  {generateYearOptions()}
                </select>
                <button onClick={toggleSelect}>Apply</button>
              </>
            ) : (
              <button onClick={toggleSelect}>
                {new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  1
                ).toLocaleString("default", { month: "long" })}{" "}
                {date.getFullYear()}
              </button>
            )}
          </div>
          <button className="nav-button" onClick={handlePrevMonth}>
            &lt;
          </button>
          <button className="nav-button" onClick={handleNextMonth}>
            &gt;
          </button>
        </div>

        <button
          className="today-button"
          onClick={handleToday}
          disabled={
            date.getMonth() === new Date().getMonth() &&
            date.getFullYear() === new Date().getFullYear()
          }
        >
          Today
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th className="week-day">Mon</th>
            <th className="week-day">Tue</th>
            <th className="week-day">Wed</th>
            <th className="week-day">Thu</th>
            <th className="week-day">Fri</th>
            <th className="week-day">Sat</th>
            <th className="week-day">Sun</th>
          </tr>
        </thead>
        <tbody>
          {monthData.map((week, index) => (
            <tr key={index}>
              {week.map((day, index) => (
                <td
                  key={index}
                  className={
                    day === null
                      ? "empty"
                      : day === new Date().getDate() &&
                        date.getMonth() === new Date().getMonth() &&
                        date.getFullYear() === new Date().getFullYear()
                      ? "date current-day"
                      : "date"
                  }
                >
                  {day}
                  {tasks
                    .filter(
                      (task) =>
                        task.day === day &&
                        task.month === date.getMonth() &&
                        task.year === date.getFullYear()
                    )
                    .map((task, taskIndex) => (
                      <div
                        key={taskIndex}
                        className="task"
                        style={{
                          backgroundColor: categoryColors[task.category],
                        }}
                      >
                        <p>{task.title}</p>
                        <p>
                          {task.startTime} - {task.endTime}
                        </p>
                      </div>
                    ))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
