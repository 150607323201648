import React from "react";
import { Link as Scroll } from "react-scroll";
import { Link } from "react-router-dom";
import "./index.scss";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Logo from "../logo";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="footer-section__about">
            <div className="footer-section__about__logo">
              <Logo />
            </div>
            <h3>Make In Motion</h3>
            <p>
              We deliver cutting-edge web solutions that empower businesses to
              achieve their full digital potential.
            </p>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-section__links">
            <h3>Quick Links</h3>
            <ul>
              <ul>
                <li>
                  <Scroll
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Home
                  </Scroll>
                </li>
                <li>
                  <Scroll
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Services
                  </Scroll>
                </li>
                <li>
                  <Scroll
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Products
                  </Scroll>
                </li>
                <li>
                  <Scroll
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    About us
                  </Scroll>
                </li>
                <li>
                  <Scroll
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    Contact
                  </Scroll>
                </li>
                <li>
                  <Link to="/dashboard" target="_blank">
                    Dashboard
                  </Link>
                </li>
              </ul>
            </ul>
          </div>
          <div className="footer-section__legal">
            <h3>Legal</h3>
            <ul>
              <ul>
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Terms of Service</Link>
                </li>
              </ul>
            </ul>
          </div>
          <div className="footer-section__social">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <Link to="#" aria-label="Instagram">
                <FaInstagram />
              </Link>
              <Link to="#" aria-label="LinkedIn">
                <FaLinkedinIn />
              </Link>
              <Link to="#" aria-label="X">
                <FaXTwitter />
              </Link>
            </div>
          </div>
          <div className="footer-section__contact">
            <h3>Contact</h3>
            <ul>
              <li>
                <strong>Email:</strong> info@makeinmotion.com
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Make In Motion. All rights reserved.
          | BE-
        </p>
      </div>
    </footer>
  );
};

export default Footer;
