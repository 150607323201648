import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

const TwoFactorCodeInput = ({ onCodeComplete }) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    // Set focus to the first input when code is reset
    if (code.every((num) => num === "")) {
      inputRefs.current[0].focus();
    }
  }, [code]);

  const handleChange = (element, index) => {
    if (!/^\d$/.test(element.value)) return;

    const newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);

    if (element.value.length === 1 && index < 5) {
      element.nextSibling.focus();
    }

    if (newCode.every((num) => num !== "")) {
      onCodeComplete(newCode.join(""));

      setTimeout(() => setCode(new Array(6).fill("")), 500);
      // TODO: Set focus on the first input
    }
  };

  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace" && code[index] === "" && index > 0) {
      const prevSibling = element.target.previousSibling;
      prevSibling.focus();
      prevSibling.value = ""; // Clear the value of the previous input
      const newCode = [...code];
      newCode[index - 1] = ""; // Update code state
      setCode(newCode);
    }
  };

  return (
    <div className="twoFactorCodeInput">
      {code.map((num, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={num}
          ref={(el) => (inputRefs.current[index] = el)}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
        />
      ))}
    </div>
  );
};

export default TwoFactorCodeInput;
