import React, { useContext } from "react";
import { UserContext } from "../../context/UserProvider";
import Login from "../login";
import { Outlet } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const { refreshToken } = useContext(UserContext);

  return refreshToken ? <Outlet /> : <Login />;
};

export default RequireAuth;
