// ============================ PACKAGE IMPORTS ============================ //

// - Import Dependencies
import { useCallback, useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

// - Import Components
import TwoFactorCodeInput from "../components/OTPInput";
import ErrorInfo from "../../components/errors/ErrorInfo";
import Loader from "../../components/loader";
import PopUp from "../../components/pop-up";

// - Import Context
import { TimerContext } from "../../context/TimerProvider";
import { AuthContext } from "../../context/AuthProvider";

// - Import Icons
import { FiLock } from "react-icons/fi";

// - Styling
import "./index.scss";

const TwoFactorAuth = () => {
  // ==================== Context ==================== //
  const { useCountdownTimer } = useContext(TimerContext);
  const { verify, loading, error } = useContext(AuthContext);

  // ==================== State ==================== //
  const [success, setSuccess] = useState(false);

  // ==================== Variables ==================== //
  const countdownDuration = 240;
  const navigate = useNavigate();

  const location = useLocation();
  const { uniqueToken } = location.state || {}; // Get the uniqueToken from the location state

  // ==================== React Hook Form ==================== //
  const methods = useForm();
  const { handleSubmit } = methods;

  // ==================== Timer Hook ==================== //
  // Initialize the countdown
  const { rawCountdown, formattedTime } = useCountdownTimer(
    countdownDuration,
    () => {
      navigate(-1);
    }
  );
  // ==================== Functions ==================== //
  const onSubmit = useCallback(
    async (data) => {
      // ==================== API Call ==================== //
      // TODO Make API call to verify the code
      const response = await verify(data, uniqueToken);

      // TODO If the response is successful, redirect to the dashboard using refrshtoken
      if (response) {
        if (response.redirectToHome) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/");
          }, 6000);
        } else navigate("/dashboard");
      }
    },
    [uniqueToken, verify, navigate]
  );

  // ==================== Render ==================== //
  return (
    <div className="two-factor-auth flex">
      <div className="lock-icon flex">
        <FiLock className="flex" />
      </div>
      <div className="two-factor-auth__title flex">
        <h1>Two-Factor Authentication</h1>
        <p>Enter the six-digit code from your authentication app</p>
        <div className="countdown">
          Time Remaining:
          <span className={`${rawCountdown < 10 ? "scale" : null}`}>
            {formattedTime}
          </span>
        </div>
      </div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group flex">
              <TwoFactorCodeInput
                onCodeComplete={(fullCode) => onSubmit(fullCode)}
              />
            </div>
          </form>
        </FormProvider>
      )}

      <ErrorInfo error={error} />
      <a href="/" className="cancel">
        Cancel
      </a>
      <PopUp
        state="success"
        showPopup={success}
        onClose={() => setSuccess(false)}
      >
        <p>
          A secure password reset link has been sent to your email. Please check
          both your inbox and spam folder for prompt access.
        </p>
      </PopUp>
    </div>
  );
};

export default TwoFactorAuth;
