// ContactSection.jsx
import React, { useRef, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import DOMPurify from "dompurify";
import ReCAPTCHA from "react-google-recaptcha";

import AOS from "aos";
import "aos/dist/aos.css";

// - Import Api
import { submitContactForm } from "../../../api/users";

// - Import styles
import "./index.scss";

import ContactInput from "../../../components/inputs/contactInput";
import ContactTextArea from "../../../components/inputs/contactTextArea";

const validationRules = {
  name: {
    required: "Name is required",
    minLength: {
      value: 2,
      message: "Name must be at least 2 characters",
    },
    maxLength: {
      value: 50,
      message: "Name must be less than 50 characters",
    },
  },
  email: {
    required: "Email is required",
    maxLength: {
      value: 128,
      message: "Email can't be longer than 128 characters",
    },
    pattern: {
      // A valid regex pattern for most email addresses
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Invalid email format",
    },
  },

  phone: {
    required: "Phone number is required",
    pattern: {
      // Adjust the regex pattern as needed to match the phone number format you expect
      value: /^\+\d{2} \d{3} \d{2} \d{2} \d{2}$/,
      message: "Invalid phone number. Must follow +32 477 77 77 77 format.",
    },
  },

  country: {
    required: "Country is required",
    minLength: {
      value: 2,
      message: "Country must be at least 2 characters",
    },
    maxLength: {
      value: 50,
      message: "Country must be less than 50 characters",
    },
  },
  message: {
    required: "Message is required",
    minLength: {
      value: 10,
      message: "Message must be at least 10 characters",
    },
    maxLength: {
      value: 1000,
      message: "Message must be less than 1000 characters",
    },
  },
};

const ContactSection = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const recaptcha = useRef();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    // Reset success state after 5 seconds if success is true
    if (success) {
      const timer = setTimeout(() => setSuccess(false), 5000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [success]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);

    const sanitizedName = DOMPurify.sanitize(data.name);
    const sanitizedEmail = DOMPurify.sanitize(data.email);
    const sanitizedPhone = DOMPurify.sanitize(data.phone);
    const sanitizedCountry = DOMPurify.sanitize(data.country);
    const sanitizedMessage = DOMPurify.sanitize(data.message);

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      setError("Please complete the reCAPTCHA challenge");
      setLoading(false);
      return;
    }

    try {
      await submitContactForm({
        name: sanitizedName,
        email: sanitizedEmail,
        phone: sanitizedPhone,
        country: sanitizedCountry,
        message: sanitizedMessage,
        captchaValue: captchaValue,
      });
      setSuccess(true);
      reset(); // Reset form fields
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contact-page" name="contact">
      <div className="contact-page__header"></div>
      <div className="contact-page__content">
        <div className="contact-info">
          <div className="contact-info__header">
            <h3 data-aos="fade-down">Let's have a Conversation</h3>
          </div>
          <div className="contact-info__content">
            <p data-aos="fade-down">
              We are always open to discussing new projects, creative ideas or
              opportunities to be part of your visions.{" "}
              <span>
                Feel free to contact us through the form or through the contact
                information below.
              </span>
            </p>
            <div className="contact-info__details">
              <div
                className="contact-info__details__item"
                data-aos="fade-right"
              >
                <div className="contact-info__details__item__icon">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_160_2)">
                      <path
                        d="M1.84622 18.5822C1.94878 19.382 2.31787 20.1239 2.89391 20.6881C3.46996 21.2524 4.21934 21.606 5.02108 21.6919C7.29936 21.9319 9.65822 22.1822 12.0771 22.1822C14.4942 22.1822 16.8548 21.9302 19.1331 21.6919C19.935 21.6066 20.6847 21.2531 21.2609 20.6888C21.8371 20.1245 22.206 19.3822 22.3079 18.5822C22.5514 16.6211 22.7914 14.5948 22.7914 12.5188C22.7914 10.4445 22.5514 8.41651 22.3079 6.45537C22.2057 5.65528 21.8368 4.91299 21.2607 4.34843C20.6846 3.78386 19.9351 3.42998 19.1331 3.34394C16.8548 3.10737 14.4942 2.85709 12.0771 2.85709C9.65994 2.85709 7.29936 3.10909 5.02108 3.34737C4.21912 3.43274 3.46941 3.78618 2.89326 4.35052C2.3171 4.91485 1.94819 5.65707 1.84622 6.45709C1.60279 8.41651 1.36279 10.4445 1.36279 12.5188C1.36279 14.5931 1.60279 16.6211 1.84622 18.5822Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.89404 6.01138L9.95461 12.368C10.5594 12.8446 11.3069 13.1038 12.0769 13.1038C12.8469 13.1038 13.5944 12.8446 14.1992 12.368L22.2598 6.01138"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_160_2">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.0771484 0.518799)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p>info@makeinmotion.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <FormProvider {...{ register, handleSubmit, errors }}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group" data-aos="fade-down">
                <ContactInput
                  label={"Name"}
                  name="name"
                  validationRules={validationRules}
                />
                <ContactInput
                  label={"Email"}
                  type={"Email"}
                  name="email"
                  validationRules={validationRules}
                />
              </div>
              <div className="form-group" data-aos="fadeLeft">
                <ContactInput
                  label={"Phone Nr."}
                  name="phone"
                  validationRules={validationRules}
                />
                <ContactInput
                  label={"Country"}
                  name="country"
                  validationRules={validationRules}
                />
              </div>
              <ContactTextArea
                label={"Message"}
                name="message"
                validationRules={validationRules}
                success={success}
                file="file"
                animation="fadeLeft"
              />
              <div className="cta">
                {" "}
                <div className="recaptcha" data-aos="fade-up">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={recaptcha}
                  />
                </div>
                <button type="submit" data-aos="fade-left">
                  {loading ? (
                    "Submitting..."
                  ) : error ? (
                    "Error"
                  ) : success ? (
                    "Success"
                  ) : (
                    <>
                      <span>Submit</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.994 3.66551C1.5405 2.54751 2.5665 1.48151 3.7065 1.87651C7.5715 3.21601 14.918 6.08751 21.368 10.4685C21.62 10.6367 21.8267 10.8645 21.9696 11.1317C22.1125 11.3989 22.1873 11.6972 22.1873 12.0003C22.1873 12.3033 22.1125 12.6016 21.9696 12.8688C21.8267 13.136 21.62 13.3638 21.368 13.532C14.918 17.9125 7.5715 20.7845 3.7065 22.124C2.5665 22.519 1.5405 21.4525 1.994 20.3345C2.969 17.933 4.083 15.6285 4.6375 14.513C4.86269 14.0631 5.25278 13.7174 5.7265 13.548L10 12L5.7265 10.4525C5.25278 10.2831 4.86269 9.93741 4.6375 9.48751C4.083 8.37201 2.969 6.06751 1.994 3.66551Z"
                          stroke="white"
                          strokeWidth="3"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
