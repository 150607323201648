// ========================== Import all modules ========================== //
import React from "react";
import { Link } from "react-router-dom";

// Import icons
import ArrowLeft from "../../../assets/icons/Arrow-Left.svg";

// Import styles
import "./index.scss";

// ========================== Create component ========================== //
const TermsAndConditions = () => {
  // ========================== Render ========================== //
  return (
    <div className="terms-and-conditions-container">
      <div className="back-button">
        <Link to="/">
          <button>
            <img src={ArrowLeft} alt="Arrow Left" />
            Back
          </button>
        </Link>
      </div>

      <h1>Terms & Conditions</h1>

      <div className="terms-section">
        <h2>1. Acceptance of Terms</h2>

        <div className="subsection">
          <p>
            1.1. By accessing or using our Services, you agree to the terms of
            these Terms & Conditions. If you do not agree with the terms, please
            do not use our Services.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <h2>2. Use of the Services</h2>

        <div className="subsection">
          <p>
            2.1. You must be at least 18 years old to use our Services. By using
            the Services, you represent and warrant that you are at least 18
            years old.
          </p>
        </div>

        <div className="subsection">
          <p>
            2.2. You agree to use the Services in accordance with all applicable
            laws, regulations, and these Terms & Conditions.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <h2>3. User Accounts and Security</h2>

        <div className="subsection">
          <p>
            3.1. To access certain features of the Services, you may be required
            to create an account. You are responsible for maintaining the
            confidentiality of your account information and password.
          </p>
        </div>

        <div className="subsection">
          <p>
            3.2. You agree to notify us immediately of any unauthorized access
            to or use of your account.
          </p>
        </div>
      </div>
      <div className="terms-section">
        <h2>4. Content and Intellectual Property</h2>

        <div className="subsection">
          <p>
            4.1. Our Services may allow you to submit, post, or display content.
            By providing such content, you grant us a non-exclusive, worldwide,
            royalty-free license to use, modify, and distribute the content on
            or through the Services.
          </p>
        </div>

        <div className="subsection">
          <p>
            4.2. You agree not to infringe on the intellectual property rights
            of others while using our Services.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <h2>5. Termination of Services</h2>

        <div className="subsection">
          <p>
            5.1. We reserve the right to terminate or suspend your access to the
            Services at any time, for any reason, with or without notice.
          </p>
        </div>

        <div className="subsection">
          <p>
            5.2. Upon termination, all provisions of these Terms & Conditions
            which by their nature should survive termination shall survive,
            including, without limitation, ownership provisions, warranty
            disclaimers, indemnity, and limitations of liability.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <h2>6. Limitation of Liability</h2>

        <div className="subsection">
          <p>
            6.1. In no event shall we be liable for any indirect, incidental,
            special, consequential, or punitive damages, or any loss of profits
            or revenues, whether incurred directly or indirectly, or any loss of
            data, use, goodwill, or other intangible losses.
          </p>
        </div>

        <div className="subsection">
          <p>
            6.2. Our total liability for any claim arising out of or relating to
            these Terms & Conditions or the use of the Services shall not exceed
            the amount paid by you, if any, for the Services in the past six
            months.
          </p>
        </div>
      </div>

      <div className="terms-section">
        <h2>7. Governing Law</h2>

        <div className="subsection">
          <p>
            7.1. These Terms & Conditions shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction].
          </p>
        </div>

        <div className="subsection">
          <p>
            7.2. Any legal action or proceeding arising out of or relating to
            these Terms & Conditions shall be brought exclusively in the courts
            of [Your Jurisdiction], and the parties consent to the personal
            jurisdiction of such courts.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
