// ========================== Import all modules ========================== //
import React from "react";
import { Link } from "react-router-dom";

// Import icons
import ArrowLeft from "../../../assets/icons/Arrow-Left.svg";

// Import styles
import "./index.scss";

// ========================== Create component ========================== //
const PrivacyPolicy = () => {
  // ========================== Render ========================== //
  return (
    <div className="privacy-policy-container">
      <div className="back-button">
        {/* Assuming you have a route defined for the page where you display the Privacy Policy */}
        <Link to="/">
          <button>
            <img src={ArrowLeft} alt="Arrow Left" />
            Back
          </button>
        </Link>
      </div>

      <h1>Privacy Policy</h1>

      <div className="policy-section">
        <h2>1. Information We Collect</h2>

        <div className="subsection">
          <p>
            1.1. <strong>Personal Information:</strong> When you register for an
            account, we collect information such as your name, email address,
            and password.
          </p>
        </div>

        <div className="subsection">
          <p>
            1.2. <strong>Usage Information:</strong> We may collect information
            about your use of the Services, such as login details, IP address,
            browser type, and device information.
          </p>
        </div>

        <div className="subsection">
          <p>
            1.3. <strong>Client Data:</strong> In the course of providing our
            Services, you may upload or provide data, documents, or other
            information ("Client Data"). We do not access or use Client Data
            except as necessary to provide the Services and as permitted by you.
          </p>
        </div>
      </div>

      <div className="policy-section">
        <h2>2. How We Use Your Information</h2>

        <div className="subsection">
          <p>
            2.1. <strong>Providing and Improving Services:</strong> We use your
            information to provide, maintain, and improve our Services,
            including ensuring the security of your account and customizing your
            experience.
          </p>
        </div>

        <div className="subsection">
          <p>
            2.2. <strong>Communications:</strong> We may use your email address
            to send you important notices about the Services, updates, and
            newsletters. You can opt-out of promotional communications at any
            time.
          </p>
        </div>

        <div className="subsection">
          <p>
            2.3. <strong>Legal Compliance:</strong> We may use your information
            to comply with applicable laws, regulations, or legal processes.
          </p>
        </div>
      </div>

      <div className="policy-section">
        <h2>3. How We Share Your Information</h2>

        <div className="subsection">
          <p>
            3.1. <strong>Service Providers:</strong> We may share your
            information with third-party service providers who assist us in
            providing the Services.
          </p>
        </div>

        <div className="subsection">
          <p>
            3.2. <strong>Legal Requirements:</strong> We may disclose your
            information if required by law, court order, or governmental
            authority.
          </p>
        </div>

        <div className="subsection">
          <p>
            3.3. <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your
            information may be transferred as part of the transaction.
          </p>
        </div>
      </div>

      <div className="policy-section">
        <h2>4. Security</h2>

        <p>
          We implement reasonable security measures to protect your information.
          However, no method of transmission over the internet or electronic
          storage is completely secure, and we cannot guarantee absolute
          security.
        </p>
      </div>

      <div className="policy-section">
        <h2>5. Your Choices</h2>

        <p>
          You can review and update your account information through your
          account settings. You may also contact us to access, correct, or
          delete your personal information.
        </p>
      </div>

      <div className="policy-section">
        <h2>6. Changes to this Privacy Policy</h2>

        <p>
          We may update this Privacy Policy from time to time. If we make
          significant changes, we will notify you by email or through the
          Services. Your continued use of the Services after the effective date
          of the updated Privacy Policy constitutes your acceptance of the
          changes.
        </p>
      </div>

      <div className="policy-section">
        <h2>7. Contact Us</h2>

        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy, please contact us at{" "}
          <a href="mailto:contact@yourcompany.com">contact@yourcompany.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
