// =================== Import All Modules =================== //

// Import Dependencies
import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

// Import styles
import "./index.scss";

const AboutSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section className="about-section" name="about">
      <div className="about-section__header">
        <h2 data-aos="fade-right">About us</h2>
      </div>
      <div className="about-section__info">
        <p data-aos="fade-left">
          Established in <strong>2024</strong>, our tech hub embodies the{" "}
          <span className="highlight-brand" id="pioneer">
            pioneering spirit
          </span>{" "}
          of computer science students committed to advancing the digital
          frontier. We aim to surpass the expected with innovative solutions
          that blend{" "}
          <span className="highlight-brand" id="creative">
            creative vision
          </span>{" "}
          with{" "}
          <span className="highlight-brand" id="techno">
            technological finesse
          </span>
          . Driven by excellence and a relentless quest for perfection, our team
          transforms complex challenges into opportunities for growth and
          innovation.
        </p>
        <p data-aos="fade-right">
          Embark with us on a mission to{" "}
          <strong>sculpt the future of tech</strong>. Explore our groundbreaking
          projects and join a community where{" "}
          <span className="highlight-brand" id="dreamers">
            dreamers and doers converge
          </span>{" "}
          to create the extraordinary.
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
