// ====================== Import All Modules ====================== //
// Import Dependencies
import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

// Import styles
import "./index.scss";

// ====================== Create Component ====================== //
const EmployeeCard = ({ employee }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // ====================== Render ====================== //
  return (
    <div className="employeeCard" data-aos="fade-up">
      <a
        href={employee.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        className="employeeCard__avatar"
      >
        <img src={employee.image} alt="Employee" />
      </a>
      <div className="content">
        <h3 className="employeeCard__name" data-aos="fade-left">
          {employee.firstName} {employee.lastName}
        </h3>
        <p className="employeeCard__position" data-aos="fade-right">
          {employee.position}
        </p>
      </div>
    </div>
  );
};

export default EmployeeCard;
