import React, { useRef, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./index.scss";
import ProjectCard from "../../../components/cards/project";
import FilterChip from "../../../components/chips/filterChip";

import projects from "../../../data/projectData";

const WorkSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const projectsContainerRef = useRef(null);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState(projects);

  useEffect(() => {
    const container = projectsContainerRef.current;

    const handleScroll = () => {
      setCanScrollPrev(container.scrollLeft > 0);
      setCanScrollNext(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    };

    container.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Filter projects based on selected filters
    const filtered =
      selectedFilters.length === 0
        ? projects
        : projects.filter((project) => selectedFilters.includes(project.group));

    setFilteredProjects(filtered);
  }, [selectedFilters]);

  const handleNavButtonClick = (direction) => {
    const container = projectsContainerRef.current;
    const scrollStep = container.clientWidth;

    if (direction === "prev") {
      container.scrollLeft -= scrollStep;
    } else if (direction === "next") {
      container.scrollLeft += scrollStep;
    }
  };

  const toggleFilter = (filter) => {
    if (filter === "All") {
      setSelectedFilters([]);
    } else {
      if (selectedFilters.includes(filter)) {
        setSelectedFilters(selectedFilters.filter((f) => f !== filter));
      } else {
        setSelectedFilters([...selectedFilters, filter]);
      }
    }
  };

  return (
    <section className="our-work-section" name="projects">
      <div className="our-work-section__header">
        <h3 data-aos="fade-right">Our work</h3>
        <p data-aos="fade-left">
          Explore some of our recent projects that showcase our creativity and
          technical expertise.
        </p>
      </div>

      <div className="filter-chips" data-aos="fade-up">
        <div
          className={`filter-chip filter-chip-all ${
            selectedFilters.length === 0 ? "selected" : ""
          }`}
          onClick={() => toggleFilter("All")}
        >
          All
        </div>
        {["Web", "Mobile", "UI/UX Design", "Other"].map((filter, index) => (
          <FilterChip
            key={index}
            filter={filter}
            toggleFilter={toggleFilter}
            selectedFilters={selectedFilters}
          />
        ))}
      </div>
      <div className="projects" ref={projectsContainerRef}>
        {filteredProjects.map((project, index) => (
          <ProjectCard project={project} key={index} />
        ))}
      </div>
      {filteredProjects.length === 0 && (
        <p>No projects found with the selected filters.</p>
      )}
      {projects.length > 0 && (
        <div className="projects__buttons">
          <button
            onClick={() => handleNavButtonClick("prev")}
            disabled={!canScrollPrev}
            style={{ display: !canScrollPrev ? "none" : "flex" }}
          >
            <span>&lt;</span>
            Prev
          </button>
          <button
            onClick={() => handleNavButtonClick("next")}
            disabled={!canScrollNext}
            style={{ display: !canScrollNext ? "none" : "flex" }}
          >
            Next <span>&gt;</span>
          </button>
        </div>
      )}
    </section>
  );
};

export default WorkSection;
