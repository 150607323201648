// =============================== Import Modules =============================== //
// React
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

// Styles
import "./index.scss";

const ProjectCard = ({ project }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Link to={`/project/${project.id}`} className="project-card">
      <div className="project-card__image" data-aos="fade-up">
        <img src={project.image} alt="" />
      </div>
      <div className="project-card__content">
        <div className="project-card__content__title">
          <h3 data-aos="fade-left">{project.title}</h3>
          <p data-aos="fade-left">{project.subtitle}</p>
        </div>
        <div className="project-card__content__description">
          <p data-aos="fade-right">{project.description}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
