// ======================= Import All Modules ======================= //

// - Import react modules
import React, { useState } from "react";
import { Link as Scroll } from "react-scroll";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../logo";
import ExternalLink from "../../../assets/icons/external-link.svg";

// - Import Stylesheets
import "./index.scss";

// ======================= Render ======================= //
const Header = () => {
  // ======================= State ======================= //

  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation();

  // ======================= Functions ======================= //

  const renderLink = (to, spy, smooth, offset, duration, children) => {
    // If we're on the home page, use Scroll
    if (location.pathname === "/") {
      return (
        <Scroll
          to={to}
          spy={spy}
          smooth={smooth}
          offset={offset}
          duration={duration}
        >
          {children}
        </Scroll>
      );
    }
    // If we're not on the home page, use Link and add the hash
    return <Link to={`/`}>{children}</Link>;
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };
  return (
    <header className="header">
      <div className="header__logo">
        <Logo />
      </div>
      <div className="menu-icon" onClick={toggleNav}>
        <input className="menu-icon__cheeckbox" type="checkbox" />
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
      <nav className={`navigation ${isNavVisible ? "visible" : ""}`}>
        <ul className="nav-list">
          <li className="nav-item dropdown">
            {renderLink("services", true, true, 0, 500, "Services")}
          </li>
          <li className="nav-item dropdown">
            {renderLink("projects", true, true, 0, 500, "Products")}
          </li>
          <li className="nav-item">
            {renderLink("about", true, true, 0, 500, "About us")}
          </li>
          <li className="nav-item">
            {renderLink("contact", true, true, 0, 500, "Contact")}
          </li>
          <li className="nav-item">
            <Link to="/login" target="_blank">
              Dashboard <img src={ExternalLink} alt="Arrow down" />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
