const house = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M13.4075 2.08003C17.025 3.68553 19.767 5.79503 21.1845 7.01353C21.901 7.62953 22.3005 8.51603 22.3495 9.45953C22.418 10.755 22.5 12.7825 22.5 15C22.5 16.706 22.4515 18.353 22.398 19.6465C22.3707 20.3668 22.071 21.0498 21.5593 21.5575C21.0477 22.0652 20.3624 22.3597 19.642 22.3815C17.912 22.4415 15.3445 22.5 12 22.5C8.6555 22.5 6.0885 22.441 4.358 22.382C3.63756 22.3602 2.95226 22.0657 2.44065 21.558C1.92905 21.0503 1.6293 20.3673 1.602 19.647C1.53663 18.0989 1.50262 16.5496 1.5 15C1.5 12.7825 1.5825 10.755 1.65 9.45953C1.7 8.51603 2.099 7.62953 2.8155 7.01353C4.233 5.79453 6.9755 3.68553 10.5925 2.08003C11.0357 1.88339 11.5152 1.7818 12 1.7818C12.4848 1.7818 12.9643 1.88339 13.4075 2.08003Z" />
      <path d="M8 19H16" />
    </svg>
  );
};

const projects = () => {
  return (
    <svg viewBox="0 0 23 22">
      <path d="M6.5667 0.891354C7.5327 0.814354 8.4712 1.22585 9.1052 1.96635L10.1262 3.15885C10.3221 3.38861 10.5654 3.57319 10.8395 3.6999C11.1135 3.82661 11.4118 3.89244 11.7137 3.89285H19.1857C20.8252 3.89285 22.1902 5.16435 22.2447 6.82085C22.2812 7.91635 22.3042 9.22885 22.3042 10.7949C22.3042 14.7924 22.1537 17.1364 22.0177 18.4379C21.9217 19.3569 21.3162 20.0739 20.4202 20.2559C19.0167 20.5414 16.3817 20.8824 11.8042 20.8824C7.2267 20.8824 4.5917 20.5414 3.1882 20.2559C2.2922 20.0739 1.6867 19.3569 1.5907 18.4379C1.4542 17.1364 1.3042 14.7924 1.3042 10.7949C1.3042 6.79735 1.4547 4.45335 1.5907 3.15185C1.6867 2.23235 2.2927 1.51535 3.1892 1.33335C3.9337 1.18185 5.0242 1.01485 6.5667 0.891354Z" />
    </svg>
  );
};

const messages = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3 8.5C19.3735 3.9355 15.338 0.5 10.5 0.5C4.977 0.5 0.5 4.977 0.5 10.5C0.5 12.398 1.029 14.1725 1.947 15.6845C1.5015 16.753 1.048 18.016 0.758 19.177C0.5955 19.8285 1.167 20.3775 1.814 20.198C2.9355 19.8865 4.1695 19.433 5.233 19.002C6.23622 19.6253 7.34253 20.0649 8.5 20.3"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 15.5C9.5 12.1865 12.1865 9.5 15.5 9.5C18.8135 9.5 21.5 12.1865 21.5 15.5C21.5 16.6515 21.176 17.727 20.6135 18.64C20.829 19.096 21.0435 19.6205 21.2135 20.183C21.407 20.826 20.831 21.3785 20.193 21.17C19.6836 21.0005 19.1838 20.8032 18.696 20.579C17.7394 21.1825 16.6311 21.5018 15.5 21.5C12.1865 21.5 9.5 18.8135 9.5 15.5Z"
        stroke="#939393"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const users = () => {
  return (
    <svg viewBox="0 0 22 22">
      <path d="M1.74437 17.989C1.06437 17.969 0.527875 17.5325 0.505375 16.853C0.497557 16.6177 0.497557 16.3823 0.505375 16.147C0.527875 15.467 1.06437 15.031 1.74437 15.011C2.24793 14.9963 2.75182 14.9963 3.25537 15.011C3.93537 15.031 4.47188 15.4675 4.49438 16.147C4.50223 16.3823 4.50223 16.6177 4.49438 16.853C4.47188 17.533 3.93537 17.969 3.25537 17.989C2.75182 18.0037 2.24793 18.0037 1.74437 17.989Z" />
      <path d="M1.74437 12.489C1.06437 12.469 0.527875 12.0325 0.505375 11.353C0.497557 11.1177 0.497557 10.8823 0.505375 10.647C0.527875 9.967 1.06437 9.531 1.74437 9.511C2.24793 9.49629 2.75182 9.49629 3.25537 9.511C3.93537 9.531 4.47188 9.9675 4.49438 10.647C4.50223 10.8823 4.50223 11.1177 4.49438 11.353C4.47188 12.033 3.93537 12.469 3.25537 12.489C2.75182 12.5037 2.24793 12.5037 1.74437 12.489Z" />
      <path d="M1.74437 6.989C1.06437 6.969 0.527875 6.5325 0.505375 5.853C0.497557 5.61773 0.497557 5.38226 0.505375 5.147C0.527875 4.467 1.06437 4.031 1.74437 4.011C2.24793 3.99629 2.75182 3.99629 3.25537 4.011C3.93537 4.031 4.47188 4.4675 4.49438 5.147C4.50223 5.38226 4.50223 5.61773 4.49438 5.853C4.47188 6.533 3.93537 6.969 3.25537 6.989C2.75182 7.0037 2.24793 7.0037 1.74437 6.989Z" />
      <path d="M2.68555 17.9995C2.70505 18.3165 2.72455 18.6075 2.74405 18.8725C2.83805 20.149 3.80055 21.139 5.07605 21.2475C6.54605 21.3735 8.81555 21.5 12 21.5C15.185 21.5 17.4545 21.3735 18.924 21.248C20.1995 21.139 21.1625 20.149 21.256 18.873C21.375 17.258 21.5 14.6825 21.5 11C21.5 7.3175 21.375 4.7415 21.256 3.1275C21.162 1.8505 20.1995 0.8615 18.924 0.7525C17.454 0.626 15.185 0.5 12 0.5C8.81505 0.5 6.54555 0.6265 5.07605 0.752C3.80105 0.8615 2.83805 1.8505 2.74405 3.127C2.72405 3.392 2.70505 3.683 2.68555 4.0005" />
      <path d="M2.55434 15C2.53052 14.1668 2.51469 13.3335 2.50684 12.5" />
      <path d="M2.50684 9.5C2.51534 8.588 2.53184 7.756 2.55434 7" />
      <path d="M14.1046 11.5355C14.6534 11.1883 15.0747 10.6723 15.3053 10.0651C15.5358 9.45799 15.563 8.79243 15.383 8.16845C15.2029 7.54447 14.8252 6.99578 14.3066 6.60481C13.788 6.21385 13.1566 6.00172 12.5071 6.00031C11.8577 5.9989 11.2253 6.20828 10.705 6.59698C10.1848 6.98569 9.80468 7.53273 9.62188 8.15592C9.43909 8.77911 9.46346 9.44479 9.69134 10.0529C9.91921 10.6611 10.3383 11.1789 10.8856 11.5285C9.4546 12.0455 8.3586 13.2365 8.0011 14.7065C7.8451 15.3465 8.2201 15.9555 8.8576 16.1235C9.5701 16.3115 10.7236 16.5 12.4851 16.5C14.2471 16.5 15.4001 16.3115 16.1126 16.1235C16.7501 15.9555 17.1251 15.347 16.9691 14.7065C16.6131 13.243 15.5261 12.0565 14.1046 11.5355Z" />
    </svg>
  );
};

const calendar = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.5H12"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 11.5H17"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.5H5"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.5H12"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16.5H17"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 16.5H5"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5015 3.28149C3.933 3.33649 3.4415 3.39399 3.0255 3.44949C1.926 3.59599 1.087 4.42349 0.9295 5.52149C0.7255 6.94349 0.5 9.20499 0.5 12.25C0.5 15.295 0.7255 17.557 0.9295 18.979C1.087 20.077 1.926 20.904 3.0255 21.051C4.5905 21.2595 7.2155 21.5 11 21.5C14.784 21.5 17.409 21.26 18.9745 21.051C20.074 20.9045 20.913 20.077 21.0705 18.979C21.2745 17.557 21.5 15.295 21.5 12.25C21.5 9.20549 21.2745 6.94349 21.0705 5.52149C20.913 4.42349 20.074 3.59599 18.9745 3.44949C18.4836 3.3846 17.9915 3.32859 17.4985 3.28149"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 3.03695C12.6669 3.01191 11.8335 2.99958 11 2.99995C10.102 2.99995 9.269 3.01345 8.5 3.03695"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5066 3.63497C13.5281 4.67497 14.2111 5.48197 15.2511 5.49797C15.4171 5.50048 15.5831 5.50048 15.7491 5.49797C16.7891 5.48197 17.4721 4.67497 17.4936 3.63497C17.5024 3.21168 17.5024 2.78826 17.4936 2.36497C17.4721 1.32497 16.7891 0.517972 15.7491 0.501972C15.5831 0.499424 15.4171 0.499424 15.2511 0.501972C14.2111 0.517972 13.5281 1.32497 13.5066 2.36497C13.4978 2.78826 13.4978 3.21168 13.5066 3.63497Z"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.50658 3.63497C4.52808 4.67497 5.21108 5.48197 6.25108 5.49797C6.41707 5.50048 6.58309 5.50048 6.74908 5.49797C7.78908 5.48197 8.47208 4.67497 8.49358 3.63497C8.50236 3.21168 8.50236 2.78826 8.49358 2.36497C8.47208 1.32497 7.78908 0.517972 6.74908 0.501972C6.58309 0.499424 6.41707 0.499424 6.25108 0.501972C5.21108 0.517972 4.52808 1.32497 4.50658 2.36497C4.49781 2.78826 4.49781 3.21168 4.50658 3.63497Z"
        stroke="#939393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Notifications = () => {
  return (
    <svg viewBox="0 0 22 22">
      <path d="M8.8652 3.95398C7.86129 3.98442 6.87126 4.06799 5.89803 4.16951C3.60367 4.40881 1.74607 6.26749 1.51726 8.56291C1.39553 9.78418 1.30225 11.0319 1.30225 12.3004C1.30225 13.5688 1.39553 14.8166 1.51726 16.0378C1.74607 18.3332 3.60367 20.1919 5.89803 20.4312C7.12751 20.5595 8.38381 20.6592 9.661 20.6592C10.9382 20.6592 12.1945 20.5595 13.4239 20.4312C15.7183 20.1919 17.5759 18.3332 17.8046 16.0378C17.8917 15.1652 17.9642 14.2789 17.9984 13.3813" />
      <path d="M16.9921 9.0165C19.5395 9.0165 20.9724 7.58357 20.9724 5.03614C20.9724 2.48871 19.5395 1.05579 16.9921 1.05579C14.4446 1.05579 13.0117 2.48871 13.0117 5.03614C13.0117 7.58357 14.4446 9.0165 16.9921 9.0165Z" />
    </svg>
  );
};

// Define unique identifiers for roles and their corresponding permissions
const rolePermissions = {
  ADMIN: ["admin"],
  EMPLOYEE: ["user"],
  CLIENT: ["user"],
};

export const hasPermission = (userRole, linkPermissions) => {
  const userPermissions = rolePermissions[userRole];
  return linkPermissions.some((permission) =>
    userPermissions.includes(permission)
  );
};

export const Links = [
  {
    title: "Dashboard",
    path: "/dashboard/overview",
    icon: house,
    permissions: ["admin", "user"],
  },
  {
    title: "Projects",
    path: "/dashboard/projects",
    icon: projects,
    permissions: ["admin", "user"],
  },
  {
    title: "Notifications",
    path: "/dashboard/notifications",
    icon: Notifications,
    permissions: ["admin", "user"],
  },
  {
    title: "Users",
    path: "/dashboard/team",
    icon: users,
    permissions: ["admin"],
  },
];
