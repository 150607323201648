import React from "react";
import "./index.scss";

import Header from "../../components/navigation/navbar";
import Footer from "../../components/footer";

const NotFoundPage = () => {
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <div className="not-found">
      <Header />
      <main className="not-found__container">
        <h1 className="not-found__title">404</h1>
        <p className="not-found__description">
          Oops! We can't seem to find the page you're looking for.
        </p>
        <button onClick={handleGoBack} className="not-found__go-back">
          Go Back
        </button>
        {/* You can also add a search bar or navigation links here */}
      </main>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
