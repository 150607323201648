import React from "react";
import "./index.scss";

const ErrorInfo = ({ error }) => {
  if (!error) {
    return null; // If no error, don't render anything
  }

  return (
    <div className="errorInfo">
      <p>{error}</p>
    </div>
  );
};

export default ErrorInfo;
