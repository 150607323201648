import Login from "./auth/login";
import TwoFactorAuth from "./auth/TwoFactorVerificationPage";
import EmailForm from "./auth/changePassword/emailForm";
import PasswordForm from "./auth/changePassword/passwordForm";
import DashboardLayout from "./pages/dashboardLayout";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/landingPage";
import PrivacyPolicy from "./pages/legal/privacyPolicy";
import TermsOfService from "./pages/legal/termsOfService";
import TermsAndConditions from "./pages/legal/termsAndConditions";
import RequireAuth from "./auth/requireAuth";
import Settings from "./pages/settings";
import Calendar from "./pages/calendar";
import ChatApp from "./pages/chat";
import ProjectDetailPage from "./pages/landingPage/work/detail";
import NotFoundPage from "./pages/notFound";

// ================== Pages ==================

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/project/:id" element={<ProjectDetailPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/2fa" element={<TwoFactorAuth />} />
      <Route path="/auth/change-password/verify" element={<TwoFactorAuth />} />
      <Route path="/change-password" element={<EmailForm />} />
      <Route path="/reset-password" element={<PasswordForm />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route element={<RequireAuth />}>
        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/calendar" element={<Calendar />} />
          <Route path="/dashboard/messages" element={<ChatApp />} />
          {/* <Route path="main" element={<SomeDashboardPage />} /> */}
          {/* Define other nested routes relative to /dashboard here */}
        </Route>
      </Route>
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="*" element={<NotFoundPage />} />

      {/* Other routes */}
    </Routes>
  );
}

export default App;
