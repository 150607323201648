// =================== Import All Modules =================== //

// Import Dependencies
import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

// Import styles
import "./index.scss";

// Import Components
import Loader from "../../../components/loader";
import EmployeeCard from "../../../components/cards/employeeCards";

// Import Api
import { getAllEmployees } from "../../../api/users";

const TeamSection = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const employees = await getAllEmployees();
        setEmployees(employees);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  return (
    <section className="team-section">
      <div className="team-section__header" data-aos="fade-down">
        <h3>Our Team</h3>
      </div>
      <div className="team-section__cards">
        {loading && <Loader />}
        {error && (
          <p className="team-error">
            {"Oops something went wrong. Try again later!"}
          </p>
        )}
        {!loading && !error && employees.length === 0 && (
          <p>No team members found.</p>
        )}
        {employees.map((employee) => (
          <EmployeeCard key={employee.id} employee={employee} />
        ))}
      </div>
    </section>
  );
};

export default TeamSection;
