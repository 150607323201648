// ============================ PACKAGE IMPORTS ============================ //

// - Import Dependencies
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

// - Import Context
import { useTheme } from "../../context/ThemeProvider";

// - Import Components
import Sidebar from "../../components/dashboard/sidebar";
import Modal from "../../components/modals/modal";

// - Import Styles
import "./index.scss";

const DashboardLayout = () => {
  // ==================== Context ==================== //
  const { theme } = useTheme();

  // ==================== State ==================== //
  const [openSidebar, setOpenSidebar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ==================== Functions ==================== //
  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const toggleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // ==================== Effects ==================== //
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Call once to initialize state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ==================== Render ==================== //
  return (
    <div className={`dashboard ${theme}`}>
      <div
        className="dashboard__sidebar"
        style={{
          width: openSidebar ? "260px" : isMobile ? "0px" : "80px",
          visibility: openSidebar || !isMobile ? "visible" : "hidden", // Set visibility based on openSidebar state
        }}
      >
        <Sidebar
          isOpen={openSidebar}
          openSidebar={toggleSidebarOpen}
          toggleSidebar={toggleSidebar}
          openModal={openModal}
        />
      </div>
      <div className="dashboard__content">
        <div
          className="dashboard__content__sidebar-button"
          onClick={toggleSidebar}
          style={{
            transform: isMobile
              ? openSidebar
                ? "translateX(265px) translateY(-50%) rotate(0deg) translateZ(0px)"
                : "translateX(0px) translateY(-50%) rotate(180deg) translateZ(0px)"
              : openSidebar
              ? "translateX(265px) translateY(-50%) rotate(0deg) translateZ(0px)"
              : "translateX(70px) translateY(-50%) rotate(180deg) translateZ(0px)",
          }}
        >
          <button>
            <div className="sidebar-button__line"></div>
            <div className="sidebar-button__line"></div>
          </button>
        </div>
        <div className="dashboard__content__body">
          <Outlet />
        </div>
      </div>
      {isModalOpen && (
        <Modal visible={isModalOpen} onClose={closeModal}>
          {modalContent}
        </Modal>
      )}
    </div>
  );
};

export default DashboardLayout;
