// ========== Users API ==========
// This file contains the API calls for the users

// === Imports ===

//import axios
import axios from "axios";

// === Constants ===
const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

// === Functions ===

// - Get All Employees
export const getAllEmployees = async () => {
  const response = await axios.get(`${baseUrl}/employees`);
  return response.data;
};

// - Login
export const loginUser = async (credentials) => {
  const response = await axios.post(`${baseUrl}/login`, credentials);
  return response.data;
};

export const forgotPassword = async (email) => {
  const response = await axios.post(`${baseUrl}/forgot-password`, email);
  return response.data;
};

export const resetPassword = async (data) => {
  const response = await axios.post(`${baseUrl}/reset-password`, data);
  return response.data;
};

export const submitContactForm = async (data) => {
  const response = await axios.post(`${baseUrl}/contact-form`, data);
  return response.data;
};

export const getUserByRefreshToken = async (refreshToken) => {
  const response = await axios.post(
    `${baseUrl}/get-user-by-refresh-token`,
    refreshToken
  );
  return response.data;
};
