// ======================= Import All Modules ======================= //
import { Link } from "react-router-dom";

// - Import Images
import Image from "../../assets/images/new_logo.png";

// - Import Styles
import "./index.scss";

// ======================= Render ======================= //
const Logo = () => {
  return (
    <Link className="logo" to="/">
      <img src={Image} alt="MakeInMotion" />
      <div className="logo__text">
        <span>Make In</span>
        <span>Motion</span>
      </div>
    </Link>
  );
};
export default Logo;
