import React, { createContext, useEffect, useState } from "react";

export const TimerContext = createContext();

// TimerProvider.js or where your useCountdownTimer hook is defined
export const useCountdownTimer = (initialCount, onExpire) => {
  const [countdown, setCountdown] = useState(initialCount);

  // Function to format countdown time
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    // Format the time as MM:SS
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (countdown <= 0) {
      onExpire?.();
      return; // Exit early if countdown is 0 or less
    }
    // Only set the interval if countdown is greater than 0
    const timerId = setInterval(() => {
      setCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;
        if (newCountdown <= 0) {
          clearInterval(timerId);
          onExpire?.();
        }
        return newCountdown;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [countdown, onExpire]);

  return { rawCountdown: countdown, formattedTime: formatTime(countdown) };
};

export const TimerProvider = ({ children }) => {
  return (
    <TimerContext.Provider value={{ useCountdownTimer }}>
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
