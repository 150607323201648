// ================== Import ================== //

// Import dependencies
import { useRef, useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import DOMPurify from "dompurify";
// import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import AOS from "aos";
import "aos/dist/aos.css";

// Import Components
import LoginInput from "../../components/inputs/loginInput";

// Import Context
import { UserContext } from "../../context/UserProvider";

// - Import Images
// import googleIcon from "../../assets/images/Google_Icons-09-512.webp";
import logo from "../../assets/images/new_logo.png";

// Import Styles
import "./index.scss";

const validationRules = {
  email: {
    required: "Email is required",
    minLength: {
      value: 5, // Minimum length for email addresses
      message: "Email must be at least 5 characters long",
    },
    maxLength: {
      value: 128, // Maximum length for email addresses
      message: "Email can't be longer than 254 characters",
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Enter a valid email address",
    },
  },
  password: {
    required: "Password is required",
    minLength: {
      value: 8, // Minimum length for passwords
      message: "Password must be at least 8 characters long",
    },
    maxLength: {
      value: 128, // Maximum length for passwords
      message: "Password can't be longer than 128 characters",
    },
  },
};

const Login = () => {
  // ================== Context ================== //

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const recaptcha = useRef();

  const { login, loading, error } = useContext(UserContext);

  // ================== States ================== //

  // - Error States
  const [errorMessages, setErrorMessages] = useState([]);

  // ================== Functions ================== //

  // Function to clear error messages
  const clearErrorMessages = () => {
    setErrorMessages([]);
  };

  // ================== Form Hook ================== //

  // - Register form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // ================== Login ================== //

  const handleLogin = (credentials) => {
    login(credentials).then((navigateCallback) => {
      if (navigateCallback) navigateCallback();
    });
  };

  // Function to handle form submission
  const onSubmit = async (data) => {
    const sanitizedEmail = DOMPurify.sanitize(data.email);
    const sanitizedPassword = DOMPurify.sanitize(data.password);

    const captchaValue = recaptcha.current.getValue();

    const credentials = {
      email: sanitizedEmail,
      password: sanitizedPassword,
      captchaValue: captchaValue,
    };
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      handleLogin(credentials);
    }

    reset();
  };

  // State to track if the screen height is less than 700px
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight < 700);

  // Function to update isSmallScreen based on window height
  const checkScreenHeight = () => {
    setIsSmallScreen(window.innerHeight < 700);
  };

  useEffect(() => {
    // Check screen height on component mount
    checkScreenHeight();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenHeight);
    };
  }, []);

  // ================== Google Login ================== //

  // const googleLogin = useGoogleLogin({
  //   flow: "auth-code",
  //   onSuccess: async (codeResponse) => {
  //     // const credentials = {
  //     //   code: codeResponse.code,
  //     // };
  //     // handleLogin(credentials);

  //     setErrorMessages(["Google login is not yet implemented"]);
  //   },
  //   onError: (errorResponse) => console.log(errorResponse),
  // });

  // ================== Effects ================== //

  useEffect(() => {
    const timeoutId = setTimeout(clearErrorMessages, 60000);

    // Cleanup the timeout when the component unmounts or if error messages change
    return () => clearTimeout(timeoutId);
  }, [errorMessages]);

  // ================== Render ================== //

  return (
    <div className="login flex">
      <div className="logo_container" data-aos="fade-down">
        <img src={logo} alt="Make In Motion Mascot" />
      </div>
      <div className="title_container">
        <p className="title" data-aos="fade-right">
          Login to your Account
        </p>
        <span className="subtitle" data-aos="fade-left">
          Get started with our app, just log in and enjoy the experience.
        </span>
      </div>
      <br />
      <FormProvider {...{ register, handleSubmit, errors }}>
        <form className="form_container" onSubmit={handleSubmit(onSubmit)}>
          <LoginInput
            label="Email"
            type="email"
            placeholder="example@gmail.com"
            name="email"
            validationRules={validationRules}
            animation="fade-right"
          />
          <LoginInput
            label="Password"
            name="password"
            type="password"
            placeholder="password"
            validationRules={validationRules}
            animation="fade-left"
          />
          <div className="recaptcha">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={recaptcha}
              data-aos={isSmallScreen ? null : "fade-right"}
            />
          </div>
          <button
            title="Sign In"
            type="submit"
            className="sign-in_btn"
            data-aos={isSmallScreen ? null : "fade-left"}
          >
            <span>{loading ? "Loading..." : "Sign In"}</span>
          </button>

          {/* <div className="separator">
            <hr className="line" />
            <span>Or</span>
            <hr className="line" />
          </div>
          <button
            title="Sign In"
            type="submit"
            className="sign-in_ggl"
            onClick={googleLogin}
          >
            <img src={googleIcon} alt="google" />
            <span>Sign In with Google</span>
          </button> */}
          <Link
            to="/change-password"
            className="note"
            data-aos={isSmallScreen ? null : "fade-up"}
          >
            Forgot password?
          </Link>
          {error ? (
            <div className="login__error flex">
              <p>{error}</p>
            </div>
          ) : null}
        </form>
      </FormProvider>
    </div>
  );
};

export default Login;
