// - Import Dependencies
import React, { createContext, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

// - Import Services
import { loginUser, getUserByRefreshToken } from "../api/users";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [refreshToken, setRefreshToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  // ================== Functions ================== //

  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getUserByRefreshToken({
        refreshToken: refreshToken,
      });
      setUser(response);
      setError(null);
    } catch (err) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }, [refreshToken]);

  const login = useCallback(
    async (data) => {
      setLoading(true);
      if (!data.password) {
        setError("Password is required");
      } else if (!data.email) {
        setError("Email is required");
      } else if (!data.captchaValue) {
        setError("Please complete the reCAPTCHA");
      }

      try {
        const response = await loginUser(data);
        if (response.redirectToVerification) {
          return () =>
            navigate("/2fa", {
              state: { uniqueToken: response.uniqueToken },
            });
        }
        setRefreshToken(response.refreshToken);
        Cookies.set("token", response.refreshToken);
        setError(null);
        navigate("/dashboard");
      } catch (err) {
        setError(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  ); // useCallback ensures this function is stable across re-renders

  // - Logout function
  const logout = () => {
    Cookies.remove("token");
    setRefreshToken(null);
    // Optionally clear other stored data or navigate
    navigate("/login");
  };

  // ================== Effects ================== //
  // Any side effects related to authentication would go here.

  useEffect(() => {
    // Check for token in cookies
    try {
      setLoading(true);
      const cookieToken = Cookies.get("token");
      if (cookieToken) {
        setRefreshToken(cookieToken);
        getUser();
      }
    } catch (err) {
      setError("Not Authorized");
    } finally {
      setLoading(false);
    }
  }, [getUser]);

  // Store token in cookies whenever it changes
  useEffect(() => {
    try {
      setLoading(true);
      if (refreshToken) {
        Cookies.set("token", refreshToken); // Expires in 7 days, adjust as needed
        getUser();
      }
    } catch (err) {
      setError("Not Authorized");
    } finally {
      setLoading(false);
    }
  }, [refreshToken, getUser]);

  return (
    <UserContext.Provider
      value={{ login, logout, loading, error, refreshToken, user }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
