// =========================== Imports =========================== //
import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
// - Import Styles
import "./index.scss";

// =========================== Components =========================== //
const ServiceCard = ({ icon, title, description, animation }) => {
  // =========================== Render =========================== //
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="service-card" data-aos={animation}>
      <img src={icon} alt={`${title} Icon`} />
      <div className="service-card__text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
