// =========================== Imports =========================== //

// - Import React dependencies
import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

// - Import Styles
import "./index.scss";

// - import components
import ServiceCard from "../../../components/cards/serviceCard";

import WebDevelopmentIcon from "../../../assets/icons/Graphic Template Website Ui.svg";
import MobileDevelopmentIcon from "../../../assets/icons/Ios Ipados.svg";
import OtherSoftwareIcon from "../../../assets/icons/Cog.svg";

const ServicesSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section className="services-section" name="services">
      <div className="services-section__header">
        <h2 data-aos="fade-left">Our Services</h2>
        <p data-aos="fade-right">Crafting Exceptional Digital Experiences</p>
      </div>
      <div className="service-category">
        <ServiceCard
          animation="fade-right"
          icon={WebDevelopmentIcon}
          title="Web Application Development"
          description="Tailored to perfection, our web applications blend innovative design with robust functionality, ensuring a seamless and adaptive user experience on any device."
        />
        <div className="service-category__line"></div>
        <ServiceCard
          animation="fade-down"
          icon={MobileDevelopmentIcon}
          title="Mobile Application Development"
          description="Our mobile apps set the standard for excellence in user experience, with customized features for iOS and Android that keep your customers engaged."
        />
        <div className="service-category__line"></div>
        <ServiceCard
          animation="fade-left"
          icon={OtherSoftwareIcon}
          title="Other Software Solutions"
          description="Step beyond conventional with our versatile software solutions. From desktop applications to integrations, we address your specific business needs with precision and innovation."
        />
      </div>
      <p className="services-section__footer" data-aos="fade-up">
        Have a unique challenge? Email us to explore if we're the right fit to
        bring your vision to life.
      </p>
    </section>
  );
};

export default ServicesSection;
