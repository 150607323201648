import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import projects from "../../../../data/projectData";

import Navbar from "../../../../components/navigation/navbar";
import Footer from "../../../../components/footer";

import AOS from "aos";
import "aos/dist/aos.css";

import "./index.scss"; // Import your stylesheet here

const ProjectDetailPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const { id } = useParams();
  const project = projects.find((p) => p.id.toString() === id);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="project-detail-page">
      <Navbar />
      <div className="project-detail-page__content">
        <img
          className="project-detail-page__content__left"
          src={project.image}
          alt={project.title}
          data-aos="fade-right"
        />
        <div className="project-detail-page__content__right">
          <h2 data-aos="fade-down">{project.title}</h2>
          <h3 data-aos="fade-left">{project.subtitle}</h3>
          <p data-aos="fade-right">{project.description}</p>
          <div className="project-info" data-aos="fade-left">
            <p>{project.info}</p>
          </div>
          <div className="technologies-used">
            {project.technologies.map((technology) => (
              <div
                className="technology-tag"
                key={technology}
                data-aos="fade-right"
              >
                {technology}
              </div>
            ))}
          </div>

          <p
            className={`project-status ${
              project.status === "In Progress" ? "unfinished" : "finished"
            }`}
            data-aos="fade-left"
          >
            {project.status === "In Progress" ? (
              <svg
                width="20"
                height="23"
                viewBox="0 0 20 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7477 13.0138C13.518 12.009 11.8503 11.4444 10.1113 11.4444C8.37236 11.4444 6.70463 12.009 5.475 13.0138C2.46077 15.477 4.06947 18.4857 4.95959 21.3953H15.2631C16.1531 18.4857 17.7618 15.477 14.7477 13.0138Z"
                  stroke="#f4a236"
                  strokeWidth="1.71"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.7477 9.87506C13.518 10.8799 11.8503 11.4444 10.1113 11.4444C8.37236 11.4444 6.70463 10.8799 5.475 9.87506C2.46077 7.41184 4.06947 4.40315 4.95959 1.49355H15.2631C16.1531 4.40315 17.7618 7.41184 14.7477 9.87506Z"
                  stroke="#f4a236"
                  strokeWidth="1.71"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.69141 1.49355H18.5313"
                  stroke="#f4a236"
                  strokeWidth="1.71"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.69141 21.3953H18.5313"
                  stroke="#f4a236"
                  strokeWidth="1.71"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.8556 13.0738C1.31594 12.446 1.31564 11.5194 1.85512 10.8913C2.24128 10.4418 2.6281 10.0001 3.0166 9.56546C2.89913 9.00206 2.78632 8.43329 2.67778 7.85776C2.52437 7.04417 2.98742 6.24156 3.76864 5.9674C4.32782 5.77114 4.88362 5.58211 5.43739 5.39993C5.61736 4.85326 5.80406 4.30428 5.99784 3.75156C6.27177 2.97027 7.07409 2.50672 7.88772 2.65989C8.46026 2.76768 9.02647 2.87985 9.58764 2.99678C10.0152 2.61492 10.4494 2.23466 10.8914 1.85505C11.5194 1.31558 12.446 1.31587 13.0739 1.85553C13.5181 2.2373 13.9543 2.6194 14.3835 3.00283C14.9541 2.88372 15.53 2.76953 16.1124 2.65989C16.9261 2.50672 17.7284 2.97027 18.0023 3.75156C18.196 4.30428 18.3827 4.85326 18.5627 5.39993C19.1166 5.58211 19.6724 5.77114 20.2316 5.9674C21.0128 6.24156 21.4758 7.04417 21.3224 7.85776C21.212 8.44317 21.0971 9.02158 20.9774 9.59446C21.3676 10.0309 21.7564 10.4745 22.1449 10.9265C22.6845 11.5544 22.6849 12.481 22.1454 13.109C21.7592 13.5586 21.3722 14.0003 20.9836 14.435C21.1012 14.9984 21.214 15.5672 21.3225 16.1427C21.476 16.9563 21.0129 17.759 20.2317 18.0331C19.6724 18.2294 19.1164 18.4185 18.5625 18.6007C18.3825 19.1472 18.1958 19.6963 18.0021 20.249C17.7282 21.0302 16.9259 21.4937 16.1123 21.3406C15.5398 21.2328 14.9737 21.1207 14.4126 21.0038C13.9851 21.3855 13.5509 21.7658 13.1091 22.1453C12.481 22.6848 11.5544 22.6845 10.9265 22.1448C10.4824 21.763 10.0462 21.3809 9.61707 20.9976C9.04632 21.1167 8.47036 21.2309 7.88786 21.3406C7.07422 21.4937 6.27192 21.0302 5.99798 20.249C5.80421 19.6963 5.61753 19.1472 5.43756 18.6007C4.88369 18.4185 4.32778 18.2294 3.76848 18.0331C2.98726 17.759 2.52422 16.9563 2.67765 16.1427C2.78806 15.5571 2.90292 14.9785 3.02261 14.4055C2.63256 13.9691 2.24392 13.5256 1.8556 13.0738Z"
                  stroke="#4caf50"
                  strokeWidth={2}
                  strokeLinejoin="round"
                  className="outer-circle"
                />
                <path
                  d="M7.91943 13.2647L10.6501 16.0806C11.935 12.3896 13.0051 10.7699 15.4286 8.57143"
                  stroke="#4caf50"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="checkmark"
                />
              </svg>
            )}
            {project.status}
          </p>
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-up"
          >
            <span>Visit Project</span>

            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7665 9.46433L8.52388 13.707C7.51967 14.7112 7.51967 16.3393 8.52388 17.3435C9.52807 18.3477 11.1562 18.3477 12.1604 17.3435L19.1305 10.3735C20.9714 8.53242 20.9714 5.54751 19.1305 3.70647C17.2894 1.86542 14.3045 1.86542 12.4635 3.70647L4.88731 11.2826C2.20944 13.9605 2.20944 18.3021 4.88731 20.98C7.5652 23.6579 11.9069 23.6579 14.5848 20.98L23.0701 12.4948"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProjectDetailPage;
