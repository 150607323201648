import Desktop from "../assets/images/Desktop.svg";
import DegrandeDevelopment from "../assets/images/degrande-development.png";

const projects = [
  {
    id: "9bfc2c32-872a-4b9d-bf11-acf07ae726c0",
    title: "Degrande Development",
    subtitle: "Portfolio website",
    description: "Portfolio website for Yani Degrande",
    info: "This portfolio website was created to display the work and expertise of Yani Degrande, a skilled developer with a keen eye for design. Utilizing a clean, modern aesthetic, the site features a responsive layout, an interactive project gallery.",
    status: "Finished",
    createdAt: "2023-11-24T14:18:37.293Z",
    image: DegrandeDevelopment,
    group: "Web",
    link: "https://yanidegrande.com/",
    technologies: ["React", "JavaScript", "HTML5", "SCSS"],
  },
  {
    id: "8d2ccd4b-75f0-437c-83f6-9973711ebab7",
    title: "Make In Motion Dashboard",
    subtitle: "Dashboard",
    description: "Dashboard for Make In Motion",
    info: "This in-progress dashboard for Make In Motion is designed to streamline the workflow for the content management team. It features a multi-level navigation system, real-time analytics, and a suite of tools for social media integration. The dashboard is built using React for efficient state management, with a focus on high performance and scalability.",
    status: "In Progress",
    createdAt: "2023-11-24T14:18:37.293Z",
    image: Desktop,
    group: "Web",
    link: "https://www.makeinmotion.com/",
    technologies: [
      "React",
      "JavaScript",
      "HTML5",
      "SCSS",
      "MySQL",
      "Node.js",
      "Prisma",
    ],
  },
];

export default projects;
